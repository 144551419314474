<template>
    <app-wrapper>
        <template v-slot:child>
            <TransactionDetails :back="true" :transaction="transaction" title="Details" />
        </template>
    </app-wrapper>
</template>

<script>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import AppWrapper from '@/layout/AppWrapper'
import TransactionDetails from '@/views/Transactions/widgets/TransactionDetails'

export default {
    name: 'Transactions',
    components: { TransactionDetails, AppWrapper },

    setup() {
        const store = useStore()
        const route = useRoute()
        const transaction = computed(() => store?.state?.Transaction?.transaction)

        onMounted(() => {
            const reference = route?.params?.id
            const prepaid = route?.query?.prepaid
            const business = route?.query?.business
            if (prepaid !== undefined && prepaid !== null) {
                store.dispatch('Transaction/getTransaction', { reference, prepaid, business })
            } else {
                store.dispatch('Transaction/getTransaction', { reference, business })
            }
        })

        return { transaction }
    }
}
</script>

<style scoped></style>